import axios from 'axios'
import { headers } from './header'

export const UserServices = {
  recuperar_Clave,
  getperfil,
  putPerfil,
  putClave,
}

function recuperar_Clave(user) {
  console.log(user)
  const url = `${process.env.VUE_APP_API_HOST_AUTH}/api/v1/usuarios/recuperar-clave`
  const requestOptions = {
    method: 'POST',
    url,
    headers: {
      'Content-Type': 'application/json',
      'x-hites-insurance': `${process.env.VUE_APP_APIKEY_API}`,
    },
    withCredentials: true,
    data: JSON.stringify({ usuario: user.value }),
  }

  // return axios(requestOptions).then(handleResponseSolicitud)
  return axios(requestOptions)
    .then(res => handleResponseSolicitud(res))
    .catch(err => err.response)
}

function handleResponseSolicitud(response) {
  const { data } = response
  if (response.status === 401) {
    logout()
    const error = (data && data.message) || response.statusText

    return Promise.reject(error)
  }
  if (response.status === 400) {
    const error = (data && data.message) || response.statusText

    return Promise.reject(error)
  }

  return response.data
}

async function getperfil(user) {
  const requestOptions = {
    method: 'GET',
    url: `${process.env.VUE_APP_API_HOST_AUTH}/api/v1/usuarios/${user}`,
    headers: headers(),
    withCredentials: true,
  }

  return axios(requestOptions)
    .then(res => res)
    .catch(err => err.response)
}

async function putPerfil(user, data) {
  const requestOptions = {
    method: 'PUT',
    url: `${process.env.VUE_APP_API_HOST_AUTH}/api/v1/usuarios/${user}`,
    headers: headers(),
    withCredentials: true,
    data,
  }

  return axios(requestOptions)
    .then(res => res)
    .catch(err => err.response)
}

async function putClave(user, data) {
  const requestOptions = {
    method: 'PUT',
    url: `${process.env.VUE_APP_API_HOST_AUTH}/api/v1/modificarClave/usuario`,
    headers: headers(),
    withCredentials: true,
    data,
  }

  return axios(requestOptions)
    .then(res => res)
    .catch(err => err.response)
}
