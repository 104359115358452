<template>
  <v-row class="match-height">
    <v-col cols="12">
      <v-container>
        <v-row justify="space-around">
          <v-card width="800">
            <v-card-title class="white--text mt-0 fondo-card">
              <v-avatar size="80">
                <v-icon class="ml-2 white--text">
                  {{ icons.mdiAccountKey }}
                </v-icon>
              </v-avatar>
              <p
                class="ml-8 white--text"
              >
                Cambiar contraseña
              </p>
            </v-card-title>

            <v-card-text>
              <v-form
                ref="formclave"
              >
                <v-row
                  class="mb-15 mt-5 ml-3 mr-3 "
                >
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      v-model="claveActual"
                      :type="isPasswordVisible ? 'text' : 'password'"
                      label="Contraseña Actual"
                      required
                      :rules="[validators.required]"
                      :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                      @click:append="isPasswordVisible = !isPasswordVisible"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      v-model="nuevaClave"
                      :type="isPasswordVisible2 ? 'text' : 'password'"
                      label="Nueva Contraseña "
                      :rules="[validators.required]"
                      :append-icon="isPasswordVisible2 ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                      @click:append="isPasswordVisible2 = !isPasswordVisible2"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      v-model="confirClave"
                      :type="isPasswordVisible3 ? 'text' : 'password'"
                      label="Confirmar contraseña"
                      persistent-hint
                      required
                      :rules="[validators.required]"
                      :append-icon="isPasswordVisible3 ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                      @click:append="isPasswordVisible3 = !isPasswordVisible3"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
              <div class="d-flex justify-end mb-3 mr-3">
                <v-btn
                  color="primary"
                  class="me-2"
                  :loading="loading"
                  :disabled="loading"
                  @click="validar"
                >
                  Cambiar contraseña
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-row>
      </v-container>
    </v-col>
    <v-snackbar
      v-model="isFormSubmittedSnackbarVisible2"
      right
      top
      :color="colorSnackbar"
    >
      {{ textSnackbar }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="isFormSubmittedSnackbarVisible2 = false"
        >
          <v-icon>{{ icons.mdiCloseThick }}</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import { mdiAccountKey, mdiCloseThick, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'

import { required } from '@core/utils/validation'
import { useRouter } from '@core/utils'
import { UserServices } from '../service/userService'

export default {
  components: {},
  setup() {
    const { router } = useRouter()
    const formclave = ref(null)
    const dialog = ref(false)
    const claveActual = ref('')
    const nuevaClave = ref('')
    const confirClave = ref('')

    const isFormSubmittedSnackbarVisible2 = ref(false)
    const colorSnackbar = ref('')
    const textSnackbar = ref('')
    const loading = ref(false)

    const isPasswordVisible = ref(false)
    const isPasswordVisible2 = ref(false)
    const isPasswordVisible3 = ref(false)

    return {
      icons: {
        mdiAccountKey,
        mdiCloseThick,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
      validators: {
        required,
      },
      dialog,
      formclave,
      claveActual,
      nuevaClave,
      confirClave,

      isFormSubmittedSnackbarVisible2,
      colorSnackbar,
      textSnackbar,
      router,
      loading,
      isPasswordVisible,
      isPasswordVisible2,
      isPasswordVisible3,
    }
  },
  methods: {
    validar() {
      const isFormValid = this.formclave.validate()

      if (!isFormValid) return
      if (this.nuevaClave !== this.confirClave) {
        this.isFormSubmittedSnackbarVisible2 = true
        this.textSnackbar = 'Nueva clave y clave confirmación no son iguales'
        this.colorSnackbar = 'error'
      } else {
        this.cambiarClave()
      }
    },
    async cambiarClave() {
      const dataUser = JSON.parse(localStorage.getItem('userData')).codigo
      const data = {
        usuario: dataUser,
        newClave: this.nuevaClave,
        oldClave: this.claveActual,
      }
      this.loading = true
      await UserServices.putClave(dataUser, data).then(resp => {
        if (resp.data.statusCode === 201 || resp.data.statusCode === 200) {
          this.isFormSubmittedSnackbarVisible2 = true
          this.textSnackbar = resp.data.message
          this.colorSnackbar = 'success'
          this.loading = false

          setTimeout(() => this.router.push({ name: 'perfil' }), 3000)
        } else {
          this.isFormSubmittedSnackbarVisible2 = true
          this.textSnackbar = resp.data.message
          this.colorSnackbar = 'error'
          this.loading = false
        }
      })
    },
  },
}
</script>
<style scoped>
.fondo-card {
  background: linear-gradient(98deg, #67afed, var(--v-primary-base) 94%);
  color: white;
}
.v-btn--fab.v-size--x-small {
  height: 20px !important;
  width: 20px !important;
}
.card-header-title {
  /* background-color: rgb(20 20 20 / 25%);*/
  background: #56ccf2;
  background: -webkit-linear-gradient(to left, #2f80ed, #56ccf2);
  background: linear-gradient(to left, #2f80ed, #56ccf2);
  color: white;
}
.card-header-title-text {
  color: #ffffff;
}
</style>
